import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { StripePlugin } from '@vue-stripe/vue-stripe';
import './assets/img/selected-option.svg';
import './assets/img/unselected-option.svg';
import './assets/img/edit.svg';
import './assets/img/delete.svg';
import './assets/img/circle-logo.png';
import './assets/scss/index.scss';

const options = {
  pk: process.env.VUE_APP_STRIPE_PUBLIC_KEY,
  testMode: process.env.VUE_APP_TEST_MODE, // Boolean. To override the insecure host warning
};

Vue.use(StripePlugin, options)
Vue.use(require('vue-moment'));
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
