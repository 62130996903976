import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/select-place',
    name: 'select-place',
    meta: {
      title: "Point de retrait"
    },
    component: () => import('../views/SelectPlace.vue')
  },
  {
    path: '/product-customization',
    name: 'product-customization',
    meta: {
      title: "Personnaliser"
    },
    component: () => import('../views/ProductCustomization.vue')
  },
  {
    path: '/cart',
    name: 'cart',
    meta: {
      title: "Mon panier"
    },
    component: () => import('../views/Cart.vue')
  },
  {
    path: '/order',
    name: 'order',
    meta: {
      title: "Paiement"
    },
    component: () => import('../views/Order.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    meta: {
      title: "Mes informations"
    },
    component: () => import('../views/Settings.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    beforeEnter(to, from, next) {
      window.location.href = "mailto:pierre@frans.coffee";
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta.title ?? "Fran's Coffee"
  })
})

export default router
